import './App.css';
import { useState } from 'react'

import Doa from './doa.json'

import Modal from './components/Modal';
import Story from './components/Story';

function App() {

  const [ openModalAkuPercaya, setOpenModalAkuPercaya ] = useState(false)
  const [ openModalBapaKami, setOpenModalBapaKami ] = useState(false)
  const [ openModalKemuliaan, setOpenModalKemuliaan ] = useState(false)
  const [ openModalTerpujilah, setOpenModalTerpujilah ] = useState(false)
  const [ openModalSalamPuteri, setOpenModalSalamPuteri ] = useState(false)
  const [ openModalSalamBunda, setOpenModalSalamBunda ] = useState(false)
  const [ openModalSalamMempelai, setOpenModalSalamMempelai ] = useState(false)
  const [ openModalDoaFatima, setOpenModalDoaFatima ] = useState(false)

  const [ openBeginning, setOpenBeginning ] = useState(true)
  const [ openEnding, setOpenEnding ] = useState(false)

  const renderHeading = () => {
    const day = new Date().getDay()
    let heading = ''
    switch (day) {
      // Monday
      case 1:
        heading = 'Peristiwa Gembira (Senin & Sabtu)'
        break;
      // Tuesday
      case 2: 
        heading = 'Peristiwa Sedih (Selasa & Jumat)'
        break;
      // Wednesday
      case 3:
        heading = 'Peristiwa Mulia (Rabu & Minggu)'
        break;
      // Thursday
      case 4: 
        heading = 'Peristiwa Terang (Kamis)'
        break;
      // Friday
      case 5: 
        heading = 'Peristiwa Sedih (Selasa & Jumat)'
        break;
      // Saturday
      case 6:
        heading = 'Peristiwa Gembira (Senin & Sabtu)'
        break;
      // Sunday
      case 7:
        heading = 'Peristiwa Mulia (Rabu & Minggu)'
        break;
      default:
        heading = 'Peristiwa Mulia (Rabu & Minggu)'
    }
    return heading
  }

  const handleAccordion = (title, e) => {
    setOpenBeginning(false)
    setOpenEnding(false)
    switch (title) {
      case 'beginning':
        setOpenBeginning(true)
        break;
      case 'ending':
        setOpenEnding(true)
        break;
    }
    window.scrollTo({
      top: e && e.nativeEvent && e.nativeEvent.offsetX,
      behavior: 'smooth'
    })
  }

  return (
    <div className="App">

      <section className='page-title'>
        Doa Rosario ✟
      </section>

      <div className='container'>

        <section className='heading'>
          {renderHeading()}
        </section>

        <section>
          <div className='inner-container'>
            <div className='main-title' onClick={(e) => handleAccordion('beginning', e)}>
              Bagian Awal
              <div className='small-text'>
                (click me)
              </div>
            </div>
            {
              openBeginning && (
                <div className='main'>
                  <div className='title'>
                    Tanda Salib
                  </div>
                  <div className='content'>
                    Dalam nama Bapa, Putera, dan Roh Kudus
                  </div>
                  <div className='title'>
                    Aku Percaya
                  </div>
                  <div className='content'>
                    <button onClick={() => setOpenModalAkuPercaya(true)}>
                      Lihat doa
                    </button>
                    <Modal 
                      isShown={openModalAkuPercaya} 
                      title={Doa.akuPercayaTitle} 
                      content={Doa.akuPercayaContent} 
                      closeModal={() => setOpenModalAkuPercaya(false)}
                    />
                  </div>
                  <div className='title'>
                    Bapa Kami
                  </div>
                  <div className='content'>
                    <button onClick={() => setOpenModalBapaKami(true)}>
                      Lihat doa
                    </button>
                    <Modal 
                      isShown={openModalBapaKami} 
                      title={Doa.bapaKamiTitle} 
                      content={Doa.bapaKamiContent} 
                      closeModal={() => setOpenModalBapaKami(false)}
                    />
                  </div>
                  <div className='title'>
                    Kemuliaan
                  </div>
                  <div className='content'>
                    <button onClick={() => setOpenModalKemuliaan(true)}>
                      Lihat doa
                    </button>
                    <Modal 
                      isShown={openModalKemuliaan} 
                      title={Doa.kemuliaanTitle} 
                      content={Doa.kemuliaanContent} 
                      closeModal={() => setOpenModalKemuliaan(false)}
                    />
                  </div>
                  <div className='title'>
                    Terpujilah
                  </div>
                  <div className='content'>
                    <button onClick={() => setOpenModalTerpujilah(true)}>
                      Lihat doa
                    </button>
                    <Modal 
                      isShown={openModalTerpujilah} 
                      title={Doa.terpujilahTitle} 
                      content={Doa.terpujilahContent} 
                      closeModal={() => setOpenModalTerpujilah(false)}
                    />
                  </div>
                  <div className='title'>
                    Salam Puteri Allah Bapa
                  </div>
                  <div className='content'>
                    <button onClick={() => setOpenModalSalamPuteri(true)}>
                      Lihat doa
                    </button>
                    <Modal 
                      isShown={openModalSalamPuteri} 
                      title={Doa.salamPutriTitle} 
                      content={Doa.salamPutriContent} 
                      closeModal={() => setOpenModalSalamPuteri(false)}
                    />
                  </div>
                  <div className='title'>
                    Salam Bunda Allah Putra
                  </div>
                  <div className='content'>
                    <button onClick={() => setOpenModalSalamBunda(true)}>
                      Lihat doa
                    </button>
                    <Modal 
                      isShown={openModalSalamBunda} 
                      title={Doa.salamBundaTitle} 
                      content={Doa.salamBundaContent} 
                      closeModal={() => setOpenModalSalamBunda(false)}
                    />
                  </div>
                  <div className='title'>
                    Salam Mempelai Allah Roh Kudus
                  </div>
                  <div className='content'>
                    <button onClick={() => setOpenModalSalamMempelai(true)}>
                      Lihat doa
                    </button>
                    <Modal 
                      isShown={openModalSalamMempelai} 
                      title={Doa.salamMempelaiTitle} 
                      content={Doa.salamMempelaiContent} 
                      closeModal={() => setOpenModalSalamMempelai(false)}
                    />
                  </div>
                  <div className='title'>
                    Kemuliaan
                  </div>
                  <div className='content'>
                    <button onClick={() => setOpenModalKemuliaan(true)}>
                      Lihat doa
                    </button>
                    <Modal 
                      isShown={openModalKemuliaan} 
                      title={Doa.kemuliaanTitle} 
                      content={Doa.kemuliaanContent} 
                      closeModal={() => setOpenModalKemuliaan(false)}
                    />
                  </div>
                  <div className='title'>
                    Terpujilah
                  </div>
                  <div className='content'>
                    <button onClick={() => setOpenModalTerpujilah(true)}>
                      Lihat doa
                    </button>
                    <Modal 
                      isShown={openModalTerpujilah} 
                      title={Doa.terpujilahTitle} 
                      content={Doa.terpujilahContent} 
                      closeModal={() => setOpenModalTerpujilah(false)}
                    />
                  </div>
                  <div className='title'>
                    Ya Yesus
                  </div>
                  <div className='content'>
                    <button onClick={() => setOpenModalDoaFatima(true)}>
                      Lihat doa
                    </button>
                    <Modal 
                      isShown={openModalDoaFatima} 
                      title={Doa.fatimaTitle} 
                      content={Doa.fatimaContent} 
                      closeModal={() => setOpenModalDoaFatima(false)}
                    />
                  </div>
                </div>
              )
            }
          </div>
        </section>

        <section>
          <Story closeOtherAccordion={handleAccordion} otherAccordionOpen={openBeginning || openEnding} />
        </section>

        <section>
          <div className='main-title' onClick={(e) => handleAccordion('ending', e)}>
            Bagian Akhir
            <div className='small-text'>
              (click me)
            </div>
          </div>
          {
            openEnding && (
              <div className='main'>
                <div className='title'>
                  Salam Mempelai Allah Roh Kudus
                </div>
                <div className='content'>
                  <button onClick={() => setOpenModalSalamMempelai(true)}>
                    Lihat doa
                  </button>
                  <Modal 
                    isShown={openModalSalamMempelai} 
                    title={Doa.salamMempelaiTitle} 
                    content={Doa.salamMempelaiContent} 
                    closeModal={() => setOpenModalSalamMempelai(false)}
                  />
                </div>
                <div className='title'>
                  Salam Bunda Allah Putra
                </div>
                <div className='content'>
                  <button onClick={() => setOpenModalSalamBunda(true)}>
                    Lihat doa
                  </button>
                  <Modal 
                    isShown={openModalSalamBunda} 
                    title={Doa.salamBundaTitle} 
                    content={Doa.salamBundaContent} 
                    closeModal={() => setOpenModalSalamBunda(false)}
                  />
                </div>
                <div className='title'>
                  Salam Puteri Allah Bapa
                </div>
                <div className='content'>
                  <button onClick={() => setOpenModalSalamPuteri(true)}>
                    Lihat doa
                  </button>
                  <Modal 
                    isShown={openModalSalamPuteri} 
                    title={Doa.salamPutriTitle} 
                    content={Doa.salamPutriContent} 
                    closeModal={() => setOpenModalSalamPuteri(false)}
                  />
                </div>
                <div className='title'>
                  Bapa Kami
                </div>
                <div className='content'>
                  <button onClick={() => setOpenModalBapaKami(true)}>
                    Lihat doa
                  </button>
                  <Modal 
                    isShown={openModalBapaKami} 
                    title={Doa.bapaKamiTitle} 
                    content={Doa.bapaKamiContent} 
                    closeModal={() => setOpenModalBapaKami(false)}
                  />
                </div>
                <div className='title'>
                  Kemuliaan
                </div>
                <div className='content'>
                  <button onClick={() => setOpenModalKemuliaan(true)}>
                    Lihat doa
                  </button>
                  <Modal 
                    isShown={openModalKemuliaan} 
                    title={Doa.kemuliaanTitle} 
                    content={Doa.kemuliaanContent} 
                    closeModal={() => setOpenModalKemuliaan(false)}
                  />
                </div>
                <div className='title'>
                  Terpujilah
                </div>
                <div className='content'>
                  <button onClick={() => setOpenModalTerpujilah(true)}>
                    Lihat doa
                  </button>
                  <Modal 
                    isShown={openModalTerpujilah} 
                    title={Doa.terpujilahTitle} 
                    content={Doa.terpujilahContent} 
                    closeModal={() => setOpenModalTerpujilah(false)}
                  />
                </div>
                <div className='title'>
                  Salam Ya Ratu
                </div>
                <div className='content'>
                  {Doa.salamYaRatuContent}
                </div>
                <div className='title'>
                  Marilah Berdoa:
                </div>
                <div className='content'>
                  {Doa.doaAkhirContent}
                </div>
                <div className='title'>
                  Tanda Salib
                </div>
                <div className='content'>
                  Dalam nama Bapa, Putera, dan Roh Kudus
                </div>
              </div>
            )
          }
        </section>

      </div>

      <section className='footer'>
        Powered by <a style={{ color: 'white' }} href='https://www.cloudxier.com'>Cloudxier</a> copyright 2024
      </section>

    </div>
  );

}

export default App;
