import { useState } from 'react'

import Doa from '../doa.json'
import PeristiwaGembira from '../peristiwa-gembira.json'
import PeristiwaMulia from '../peristiwa-mulia.json'
import PeristiwaSedih from '../peristiwa-sedih.json'
import PeristiwaTerang from '../peristiwa-terang.json'

import Modal from './Modal'

function Story ({ closeOtherAccordion, otherAccordionOpen }) {

	const [ openModalBapaKami, setOpenModalBapaKami ] = useState(false)
  const [ openModalKemuliaan, setOpenModalKemuliaan ] = useState(false)
  const [ openModalTerpujilah, setOpenModalTerpujilah ] = useState(false)
	const [ openModalDoaFatima, setOpenModalDoaFatima ] = useState(false)
	const [ openModalSalamMaria, setOpenModalSalamMaria ] = useState(false)

	const [ openStory, setOpenStory ] = useState(null)

	const day = new Date().getDay()

	let content = {}
	switch (day) {
		// Monday
		case 1:
			content = PeristiwaGembira
			break;
		// Tuesday
		case 2:
			content = PeristiwaSedih
			break;
		// Wednesday
		case 3:
			content = PeristiwaMulia
			break;
		// Thursday
		case 4:
			content = PeristiwaTerang
			break;
		// Friday
		case 5:
			content = PeristiwaSedih
			break;
		// Saturday
		case 6:
			content = PeristiwaGembira
			break;
		// Sunday
		case 7:
			content = PeristiwaMulia
			break;
		default:
			content = PeristiwaGembira
	}

	const renderContent = () => {
		let rendered = []
		for (let key in content) {
			const title = content[key].title
			const text = content[key].content
			const prayer = content[key].prayer
			rendered.push(
				<div className='inner-container'>
					<div className='main-title' onClick={(e) => {
						closeOtherAccordion()
						setOpenStory(key)
						window.scrollTo({
							top: e && e.nativeEvent && e.nativeEvent.offsetX + 100,
							behavior: 'smooth'
						})
					}}>
						Peristiwa {key}
						<div className='small-text'>
							(click me)
						</div>
					</div>
					{
						openStory && openStory == key && !otherAccordionOpen && (
							<div className='main'>
								<div className='title'>
									{title}
								</div>
								<div className='content'>
									{text}
								</div>
								<div className='title'>
									Doa
								</div>
								<div className='content'>
									{prayer}
								</div>
								<div className='title'>
									Bapa Kami
								</div>
								<div className='content'>
									<button onClick={() => setOpenModalBapaKami(true)}>
										Lihat doa
									</button>
									<Modal 
										isShown={openModalBapaKami} 
										title={Doa.bapaKamiTitle} 
										content={Doa.bapaKamiContent} 
										closeModal={() => setOpenModalBapaKami(false)}
									/>
								</div>
								<div className='title'>
									Salam Maria (10x)
								</div>
								<div className='content'>
									<button onClick={() => setOpenModalSalamMaria(true)}>
										Lihat doa
									</button>
									<Modal 
										isShown={openModalSalamMaria} 
										title={Doa.salamMariaTitle} 
										content={Doa.salamMariaContent} 
										closeModal={() => setOpenModalSalamMaria(false)}
									/>
								</div>
                <div className='title'>
									Kemuliaan
								</div>
								<div className='content'>
									<button onClick={() => setOpenModalKemuliaan(true)}>
										Lihat doa
									</button>
									<Modal 
										isShown={openModalKemuliaan} 
										title={Doa.kemuliaanTitle} 
										content={Doa.kemuliaanContent} 
										closeModal={() => setOpenModalKemuliaan(false)}
									/>
								</div>
								<div className='title'>
									Terpujilah
								</div>
								<div className='content'>
									<button onClick={() => setOpenModalTerpujilah(true)}>
										Lihat doa
									</button>
									<Modal 
										isShown={openModalTerpujilah} 
										title={Doa.terpujilahTitle} 
										content={Doa.terpujilahContent} 
										closeModal={() => setOpenModalTerpujilah(false)}
									/>
								</div>
								<div className='title'>
									Ya Yesus
								</div>
								<div className='content'>
									<button onClick={() => setOpenModalDoaFatima(true)}>
										Lihat doa
									</button>
									<Modal 
										isShown={openModalDoaFatima} 
										title={Doa.fatimaTitle} 
										content={Doa.fatimaContent} 
										closeModal={() => setOpenModalDoaFatima(false)}
									/>
								</div>
							</div>
						)
					}
				</div>
			)
		}
		return rendered
	}

	return renderContent()


}

export default Story