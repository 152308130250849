function Modal ({ title, content, isShown, closeModal }) {

	if (isShown) {
		return (
			<div className='modal-container'>
				<div className="modal-content">
					<div className='close-button-container'>
						<button className='close-button' onClick={closeModal}>
							X
						</button>
					</div>
					<div className='title'>
						{title}
					</div>
					<div className='content'>
						{content}
					</div>
				</div>
			</div>
		)
	} else {
		return <></>
	}

}

export default Modal